import { BrowserRouter } from 'react-router-dom';

// routes
import { AllRoutes } from './index';
import {AuthProvider} from '../hooks/AuthProvider';
import {PrimeReactProvider} from "primereact/api";

const Routes = () => {
  return (
       <PrimeReactProvider>
    <BrowserRouter>
      <AuthProvider>
        <AllRoutes />
      </AuthProvider>
    </BrowserRouter>
       </PrimeReactProvider>
  );
};

export default Routes;
