import { Navigate, useLocation, useNavigate } from 'react-router-dom';

// helpers
import { APICore } from '../helpers/api/apiCore';

// hooks
import { useUser } from '../hooks';
import useAuth from "../hooks/useAuth";
import axios from 'axios';
import { useEffect } from 'react';

type PrivateRouteProps = {
    component: React.ComponentType;
    roles: string[];
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const homePage=['/','/','/','/tickets/bug','/tickets/bug']
const PrivateRoute =  ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
    let location = useLocation();
    const [loggedInUser] = useUser();
    const {auth, setAuth} = useAuth();
    const navigate = useNavigate();

    const api = new APICore();

    /**
     * not logged in so redirect to login page with the return url
     */
    //  if ( api.isUserAuthenticated() === false) {
    //     return <Navigate to={'/login'} state={{ from: location }} replace />;
    // }
    useEffect(() => {
        console.log(api.isUserAuthenticated());
        if ( api.isUserAuthenticated() === false) {
            navigate('/login');
        }
        else {
            if (!auth.email) {
                axios.get('/home/', {
                    withCredentials: true
                }).then((response) => {
                        const main_role = response.data.main_role;
                        const current_role = main_role;
                        const first_name = response.data.first_name;
                        const last_name = response.data.last_name;
                        const email = response.data.email;
                        const projects = response.data.projects;
                        const users = response.data.users;
                        const last_login = response.data.last_login;
                        const username=response.data.username;
                        const notifications=response.data.notifications
                        setAuth({ username,last_login,first_name, last_name, email, main_role, current_role, projects, users,notifications });
                    
                }).catch(err=>{
                    console.log(err);
                    if(err=='Invalid credentials'){
                        localStorage.removeItem('LoggedIn');
                        navigate('/login');
                    }else if(err=='change password'){
                      navigate('/ChangePassword');
                    }
                    else navigate('/error-500');
                });
            }
        }
    }, [auth.email, setAuth]);
    
        
    let rolle = "";
rolle = auth.current_role;

// Convert the comma-separated string into an array
const rolleArray = rolle?.split(',').map(Number); // Convert string roles to numbers

if (roles && rolleArray?.length > 0) {
    let isAuthorized = false;

    for (let i in roles) {
        // Check if any of the current roles match the allowed roles
        if (rolleArray.includes(Number(roles[i]))) {
            isAuthorized = true;
            break; // No need to continue if we found a match
        }
    }

    // If not authorized, redirect to the home page with the lowest role number
    if (!isAuthorized) {
        // Find the minimum role number
        const minRole = Math.min(...rolleArray);

        // Redirect to the corresponding home page
        navigate(homePage[minRole]); // Use minRole to index into the homePage array
    }
}
    return <RouteComponent />;
};

export default PrivateRoute;
