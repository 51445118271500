import { useContext } from "react";
import AuthContext from "./AuthProvider";

type AuthContextType = {
    auth: any; // Replace 'any' with the appropriate type for your auth object
    setAuth: (auth: any) => void; // Replace 'any' with the appropriate type for your auth object
  };
  const useAuth = (): AuthContextType => {
    return useContext(AuthContext) as AuthContextType;
  };

export default useAuth;