import { APICore } from './apiCore';

const api = new APICore();

// account
function login(params: { email: string; password: string }) {
    const baseUrl = '/accounts/login/';
    return api.create(`${baseUrl}`, params);

}
function otp(params: { otp:number }) {
    const baseUrl = '/accounts/otp_verify/';
    return api.create(`${baseUrl}`, params);
}

function logout() {
    const baseUrl = '/accounts/logout/';
    return api.get(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
    const baseUrl = '/accounts/register/';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
    const baseUrl = '/accounts/reset/';
    return api.create(`${baseUrl}`, params);
}

export { login, otp, logout, signup, forgotPassword};
